export const scrollToElement = (selector) => {
  const contactElem = document.querySelector(selector)
  const contactPosition = contactElem.getBoundingClientRect()
  if(window && contactPosition.y) {
    window.scroll({
      top: contactPosition.y + window.scrollY - 100,
      behavior: 'smooth'
    })
  }
}
