import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import * as _ from 'lodash'
import ContactFooter from "../components/ContactFooter";
import ContactForm from "../components/ContactForm";
import * as helper from "../utils";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { scrollToElement } from "../components/utils";

export const SeoLandingPageBTemplate = ({title, subtitle, text, formName, image, cards, topSection, process, contentComponent}) => {
    const PageContent = contentComponent || Content

    return (
        <React.Fragment>
            <section className={'hero is-large flex-hero'}>
                <div className="hero-body margin-top-0"
                     style={{
                         backgroundImage: `url(${
                             !!image.childImageSharp
                                 ? image.childImageSharp.fluid.src
                                 : image
                         })`,
                         backgroundPosition: `center bottom`,
                         backgroundAttachment: `fixed`,
                         backgroundSize: 'cover',
                         backgroundRepeat: 'no-repeat',
                         display: 'flex'
                     }}>
                    <div className={'container d-flex'}>
                        <div className={'columns landing-page-b--hero-container'} style={{
                            // height: 'calc(100% - 127px)',
                            alignItems: 'center',
                        }}>
                          <div className={'column'}>
                            <div className={'columns is-align-items-center has-black-background'}
                                  style={{borderRadius: '0.75rem'}}>
                            <div style={{
                                lineHeight: '1',
                                opacity: '0.99',
                            }} className={'column pt-mobile-5 pb-mobile-4 mt-mobile-4 p-3'}>

                                <h1 className="h1 is-size-3-mobile is-size-1-tablet is-size-1-widescreen"
                                    style={{
                                        fontFamily: 'Museo',
                                        fontWeight: '500',
                                        color: 'white',
                                        lineHeight: '1.2',
                                        padding: '0.3rem',
                                        // maxWidth: '54vh',
                                        // margin: '0 auto',
                                        whiteSpace: 'pre-line'
                                    }}>
                                    {title}
                                </h1>
                                <h3 className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-weight-semibold"
                                    style={{
                                        color: 'white',
                                        lineHeight: '1.2',
                                        padding: '0.3rem',
                                        maxWidth: '96%',
                                        // margin: '0 auto',
                                        letterSpacing: '0.5px'
                                    }}>
                                    {subtitle}
                                </h3>

                                <div className="is-size-6-mobile is-size-6-tablet is-size-6-widescreen"
                                     style={{
                                         color: 'white',
                                         lineHeight: '1.2',
                                         padding: '0.25rem',
                                         // margin: '0 auto',
                                         letterSpacing: '0.5px'
                                     }}>
                                    <div className={'hero-description-list'}>
                                        {helper.parse(text)}
                                    </div>
                                </div>

                            </div>
                              <div style={{
                                lineHeight: '1',
                                order: '1'
                              }} className={'column p-0 mb-mobile-4'}>
                                <div className={'card rounded-corners p-3 h-auto'} style={{
                                  backgroundColor: 'rgba(255,255,255,0.96)'
                                }}>
                                  <h3 id='contact' className={'font-primary is-ligna-font pt-1'}>Contact Us</h3>
                                  <hr className={'my-3'}/>
                                  <ContactForm name={formName}/>
                                </div>
                              </div>
                            </div>
                            <div className={'mb-8 columns testimonials-carousel'}>
                              <Carousel centerMode={true} showIndicators={false} showStatus={false} autoPlay={true}
                                        showArrows={true} showThumbs={false} infiniteLoop={true} emulateTouch={true}>
                                <Testimonial
                                  name={'Karen Reddy'}
                                  description={'Homeowner'}
                                  stars={5}
                                  text={'Ligna prepared an arboricultural impact survey for us in relation to some\n' +
                                  '          neighbouring trees as part of our planning application for an extension. \n' +
                                  '          Ben and his colleague Jen provided a very polite, friendly and efficient service\n' +
                                  '          for us at a very cost-effective price, compared to competitors. \n' +
                                  '          We were very pleased with the service provided and would highly recommend them.'}
                                />
                                <Testimonial
                                  name={'Dale Robinson'}
                                  description={'Twenty-Nine Architecture'}
                                  stars={5}
                                  tagline={'Highly recommended for all architectural practices.'}
                                  text={'Ben and his team are dedicated and enthusiastic professionals who provide an\n' +
                                  ' outstanding service in a quick time frame. We have no hesitation in\n' +
                                  ' recommending their services and will continue to use them on all our projects\n' +
                                  ' going forward. '}
                                />
                                <Testimonial
                                  name={'Nick Brod'}
                                  description={'Homeowner'}
                                  stars={5}
                                  text={'We required a tree survey undertaken to support a planning application for our\n' +
                                  ' Cart Lodge. After initial enquiry, Ben responded immediately and organised a visit\n' +
                                  ' to survey the Tree and location. Jen was very informative when she undertook the\n' +
                                  ' survey and the report was back with us within a couple of days.\n' +
                                  ' Brilliant communication, engagement and service. '}
                                />
                              </Carousel>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              <span className={'carousel-root'}></span>
            </section>

            <section className={'half-overlay-top is-hidden-mobile'}>
                <div className={'container'}>
                    <div className="columns">
                        {_.map(cards, service => {
                            return <div className="column">
                                <div className={'card no-padding white-bg rounded-corners'} style={{maxWidth: '500px'}}>
                                    <div className={'card-header has-padding white-bg float-icon pb-0'}>
                                        <div className={'icon-circle-container'}>
                                            <div className={'icon-circle primary-bg'}>
                                                <i className={service.icon + (' font-white')}></i>
                                            </div>
                                        </div>
                                        <h3 className="card-title font-primary has-text-centered">
                                            {service.title}
                                        </h3>
                                        <p className="card-subtitle has-text-centered">{service.subtitle}</p>
                                    </div>
                                    <div className={'card-body pt-2'}>
                                        <p className={'card-first-para'}>{service.text}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
            <section className={'section dark-green-bg border-top-primary'}>
                <h4 className={'has-text-centered font-primary'}>/ &nbsp;{topSection.title} &nbsp;/</h4>
                <div className={'centered-text-container line-left font-white'}>
                    <p className={'text-first-para font-white'}>{topSection.firstPara}</p>
                    <p className={'text'}>{topSection.text}</p>
                </div>
              <HowItWorks/>
            </section>

            {_.map(process.stages, (stage, index) => {
                return <section className={'section light-green-bg border-top-primary'}>
                    <div className={'container'}>
                        {index === 0 ?
                            <h4 className={'has-text-centered font-primary'}>/ &nbsp;{process.title} &nbsp;/</h4> : ''}
                        <div className={'columns mobile-margin-bottom my-4'}>
                            <div className={'column is-3 d-flex'}>
                                <div className={'stage-title-container my-auto'}>
                                    <h3 className={'font-primary'}>{stage.title}</h3>
                                    <h5 className={'font-primary'}>{stage.subtitle}</h5>
                                </div>
                            </div>
                            {_.map(stage.children, child => {
                                return <div className="column">
                                    <div className={'card no-padding white-bg rounded-corners'}>
                                        <div className={'card-header has-padding primary-bg float-icon'}>
                                            <div className={'icon-circle-container'}>
                                                <div className={'icon-circle border-primary'}>
                                                    <i className={child.icon}></i>
                                                </div>
                                            </div>
                                            <h3 className="card-title font-white has-text-centered px-3">
                                                {child.title}
                                            </h3>
                                            <p className="card-subtitle has-text-centered">{child.subtitle}</p>
                                        </div>
                                        <div className={'card-body'}>
                                            <p className={'card-first-para'}>{child.tagline}</p>
                                            <p>{child.text}</p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </section>
            })}
            <ContactFooter/>
        </React.Fragment>
    )
}

const HowItWorks = () => {
  return <div className={'pt-6 mt-8 mb-4 has-text-centered line-top'}>
    <h4 className={'has-text-centered font-primary'}>/ &nbsp;How it works &nbsp;/</h4>
    <div className={'columns font-white p-4'}>
      <div className={'column has-text-centered'}>
        <div className={'icon-circle-container'}>
          <div className={'icon-large font-primary'}>
            <i className="fal fa-comments-alt"></i>
          </div>
        </div>
        <h3 className={'font-primary is-size-3 is-ligna-font'}>Step 1</h3>
        <p>Fill out the enquiry form or give us a call. We'll put together a quote for the required documents.</p>
      </div>
      <div className={'column has-text-centered'}>
        <div className={'icon-circle-container'}>
          <div className={'icon-large font-primary'}>
            <i className="fal fa-calendar-check"></i>
          </div>
        </div>
        <h3 className={'font-primary is-size-3 is-ligna-font'}>Step 2</h3>
        <p>Once you've accepted the quote, we'll arrange a date for the site visit - this is often within 3 days of
          quote acceptance.</p>
      </div>
      <div className={'column has-text-centered'}>
        <div className={'icon-circle-container'}>
          <div className={'icon-large font-primary'}>
            <i className="fal fa-thumbs-up"></i>
          </div>
        </div>
        <h3 className={'font-primary is-size-3 is-ligna-font'}>Step 3</h3>
        <p>When your report is ready, we'll email it to you. Once you've read it through and we've answered any
          questions, we'll send you the invoice.</p>
      </div>
    </div>
    <a onClick={_=>scrollToElement('#contact')} className={'button primary-bg border-primary font-white has-text-weight-semibold is-size-2 is-size-4'}>Contact Us</a>
  </div>
}

const Testimonial = ({name, description, text, stars = 0, tagline = null}) => {
  return <div className={'w-100 d-flex is-justify-content-center'}>
    <div className={'testimonial-container'}>
      <div className={'testimonial'}>
        <div className={'d-flex top-info'}>
          <div>
            <h5>{name}</h5>
            <h6>{description}</h6>
          </div>
          <div className={'font-primary'}>
            {[...Array(stars)].map(_ => <i className="fas fa-star"></i>)}
          </div>
        </div>
        {tagline && <b>{tagline}</b>}
        <p>
          {text}
        </p>
      </div>
    </div>
  </div>
}

SeoLandingPageBTemplate.propTypes = {
    // title: PropTypes.string.isRequired,
    // content: PropTypes.string,
    // contentComponent: PropTypes.func,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

const SeoLandingPageB = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout phone={'01284 598013'} email={'team@lignaconsultancy.co.uk'}>
            <SeoLandingPageBTemplate
                contentComponent={HTMLContent}
                title={frontmatter.title}
                subtitle={frontmatter.subtitle}
                text={frontmatter.text}
                formName={frontmatter.formName}
                image={frontmatter.image}
                cards={frontmatter.cards}
                topSection={frontmatter.topSection}
                process={frontmatter.process}
            />
        </Layout>
    )
}

SeoLandingPageB.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SeoLandingPageB

export const SeoLandingPageBQuery = graphql`
  query SeoLandingPageB($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        text
        formName
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cards {
            title
            icon
            text
        }
        topSection {
            title
            firstPara
            text
        }
        process {
            title
            stages {
                title
                subtitle
                children {
                    title
                    subtitle
                    icon
                    text
                    link
                }
            }
        }
      }
    }
  }
`
