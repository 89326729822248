import React from 'react';
import remark from 'remark';
import html from 'remark-html';
import breaks from 'remark-breaks';
import ReactHtmlParser from 'react-html-parser';


export function parse(content) {
    let parsed = '';
    remark()
        .use(breaks)
        .use(html)
        .process(content, function(err, file) {
            if (err){
                console.error(err)
            }
            parsed = (String(file))
        });
    return ReactHtmlParser(parsed);
}