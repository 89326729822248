import React from 'react'
import {navigate} from 'gatsby-link'
import {trackCustomEvent} from 'gatsby-plugin-google-analytics'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {isValidated: false}
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    getParam = (p) => {
        if(typeof window === 'undefined') return null;
        let match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
        if(!match) {
            return null;
        }
        return decodeURIComponent(match[1].replace(/\+/g, ''))
    }

    handleSubmit = e => {
        e.preventDefault()

        const gclid = this.getParam('gclid');
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: encode({
                'form-name': form.getAttribute('name'),
                gclid,
                ...this.state,
            }),
        })
            .then(() => {
                trackCustomEvent({
                    category: "Contact",
                    action: "Submitted successfully",
                    label: form.getAttribute('name')
                });
                return navigate(form.getAttribute('action'))
            })
            .catch(error => alert(error))
    }

    render() {
        return (
            <div className="contact-form">
                <form
                    name={this.props.name || 'contact'}
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value={this.props.name || 'contact'}/>
                    <input type="hidden" name="gclid" value={this.getParam('gclid')}/>
                    <div hidden>
                        <label>
                            Don’t fill this out:{' '}
                            <input name="bot-field" onChange={this.handleChange}/>
                        </label>
                    </div>
                    <div className="field">
                        <label className="label" htmlFor={'name'}>
                            Your name
                        </label>
                        <div className="control">
                            <input
                                className="input"
                                type={'text'}
                                name={'name'}
                                onChange={this.handleChange}
                                id={'name'}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={'columns mb-0'}>
                        <div className={'column is-12 is-half-desktop'}>
                            <div className="field">
                                <label className="label" htmlFor={'email'}>
                                    Email
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type={'email'}
                                        name={'email'}
                                        onChange={this.handleChange}
                                        id={'email'}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'column is-12 is-half-desktop'}>
                            <div className="field">
                                <label className="label" htmlFor={'phone'}>
                                    Phone <small>(optional)</small>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type={'phone'}
                                        name={'phone'}
                                        onChange={this.handleChange}
                                        id={'phone'}
                                        required={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label" htmlFor={'message'}>
                            Message
                        </label>
                        <div className="control">
                    <textarea
                        className="textarea"
                        name={'message'}
                        onChange={this.handleChange}
                        id={'message'}
                        rows={3}
                        required={true}/>
                        </div>
                    </div>
                    <div className="field">
                        <button className="button btn-primary w-100 mt-2" type="submit">
                            {this.props.buttonText || "Send"}
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}
