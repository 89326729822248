import React from 'react'
import {Link} from 'gatsby'


const ContactFooter = class extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <section className={'section mid-green-bg p-4' + (this.props.borderTop ? ' border-top-primary':'')}>
            <div className={'container is-ligna-font has-text-centered'}>
                <h3 className={'font-pale-green mb-3'}>Need more information about how we can help?</h3>
                <h2 className={'font-white'}>Take a look at our Services section or&nbsp;
                    <Link to="/contact" className={"font-pale-green text-underlined"} activeClassName={'is-active'}>Contact Us.</Link></h2>
            </div>
        </section>
    }
}

export default ContactFooter
